<template>
<div id="div-home">
  <div class="Message" v-for="(dt, index) in res" :key="index">
    <div :class="'Message-icon Message--' + dt.type">
      <router-link :to="'/profile/' + dt.user_id + '/' + dt.username"><img v-lazy="'/users/' + Math.floor(dt.user_id / 1000) + '/' + dt.username + '/imgp.jpg'"></router-link>
    </div>
    <div class="Message-body">
      <p>{{$t('notificacoes.' + dt.type).replace('@user', dt.username)}}</p>
      <!--<p class="u-italic"></p>-->
      <button v-if="dt.type === 'pendingfollow'" class="Message-button">{{$t('notificacoes.btn_pendingfollow')}}</button>
    </div>
  </div>
<!--
  <div class="Message Message--orange">
    <div class="Message-icon">
      <i class="fa fa-exclamation"></i>
    </div>
    <div class="Message-body">
      <p>This is a simple notification with a brighter color...</p>
      <p>If you bring you mouse here you can close it manually.</p>
    </div>
    <button class="Message-close js-messageClose"><i class="fa fa-times"></i></button>
  </div>

  <div class="Message Message--green">
    <div class="Message-icon">
      <i class="fa fa-check"></i>
    </div>
    <div class="Message-body">
      <p>This is a message telling you that everything is a-okay!</p>
      <p>Good job, and good riddance.</p>
    </div>
    <button class="Message-close js-messageClose"><i class="fa fa-times"></i></button>
  </div>

  <div class="Message Message--red">
    <div class="Message-icon">
      <i class="fa fa-times"></i>
    </div>
    <div class="Message-body">
      <p>This is a notification that something went wrong...</p>
      <button class="Message-button" id="js-helpMe">Yikes, help me please!</button>
      <button class="Message-button js-messageClose">Don't care.</button>
    </div>
    <button class="Message-close js-messageClose"><i class="fa fa-times"></i></button>
  </div>

  <div class="Message">
    <div class="Message-icon">
      <i class="fa fa-bell-o"></i>
    </div>
    <div class="Message-body">
      <p>Do you know that you can assign status and relation to a company right in the visit list?</p>
      <button class="Message-button" id="js-showMe">Show me how</button>
      <button class="Message-button js-messageClose">Nah, not interested</button>
    </div>
    <button class="Message-close js-messageClose"><i class="fa fa-times"></i></button>
  </div>

  <div class="Message Message--orange">
    <div class="Message-icon">
      <i class="fa fa-exclamation"></i>
    </div>
    <div class="Message-body">
      <p>You haven't authorized your LinkedIn account. Would you like some help with that?</p>
      <p class="u-italic">With your account connected we can show you what connections you have at a company that visited your site!</p>
      <button class="Message-button" id="js-authMe">Authorize!</button>
      <button class="Message-button js-messageClose">I'll just keep using carrier pigeons</button>
    </div>
    <button class="Message-close js-messageClose"><i class="fa fa-times"></i></button>
  </div>
-->
</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { notifications, notificationRead } from './../resources/fn'

export default {
  name: 'notifications',
  data () {
    return {
      res: []
    }
  },
  beforeMount () {
    this.SET_LOAD(true)
    notifications(this.axios, {}).then((res) => {
      this.res = res.data
      this.SET_LOAD()
    }, (res) => {
      this.SET_LOAD()
    })
  },
  computed: {
    ...mapState({
      user: 'user'
    })
  },
  methods: {
    ...mapMutations([
      'SET_LOAD'
    ]),
    readFN (type, username) {
      notificationRead(this.axios, type, username).then(() => {
      }, () => {})
    }
  }
}
</script>

<style>

.Message {
  display: table;
  position: relative;
  margin: 10px auto 0;
  max-width: 500px;
  width: 100%;
  background-color: #0074d9;
  color: #fff;
  transition: all 0.2s ease;
}
.Message.is-hidden {
  opacity: 0;
  height: 0;
  font-size: 0;
  padding: 0;
  margin: 0 auto;
  display: block;
}

.Message--message {
  background-color: #f39c12;
}

.Message--visit {
  background-color: #ff4136;
}

.Message--like {
  background-color: #2ecc40;
}
.Message--comment {
  background-color: #0987ff;
}
.Message--pendingfollow {
  background-color: #ff0993;
}
.Message--acceptfollow {
  background-color: #440943;
}
.Message--follow {
  background-color: #875424;
}

.Message-icon {
  display: table-cell;
  vertical-align: middle;
  width: 60px;
  padding: 30px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.25);
}
.Message-icon > i {
  width: 20px;
  font-size: 20px;
}

.Message-body {
  display: table-cell;
  vertical-align: middle;
  padding: 30px 20px 30px 10px;
}
.Message-body > p {
  line-height: 1.2;
  margin-top: 6px;
}

.Message-button {
  position: relative;
  margin: 15px 5px -10px;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px rgba(0, 0, 0, 0.4);
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  font-family: "Source Sans Pro";
  color: #fff;
  outline: none;
  cursor: pointer;
}
.Message-button:hover {
  background: rgba(0, 0, 0, 0.3);
}
.Message-button:active {
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0px rgba(0, 0, 0, 0.4);
  top: 3px;
}

.Message-close {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  border: none;
  outline: none;
  font-size: 20px;
  right: 5px;
  top: 5px;
  opacity: 0;
  cursor: pointer;
}
.Message:hover .Message-close {
  opacity: 1;
}
.Message-close:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.u-italic {
  font-style: italic;
}
</style>
